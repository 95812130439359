import { useEventListener, useSupported } from '@vueuse/core'

export function useVisualViewport() {
  const supported = useSupported(
    () => typeof window !== 'undefined' && 'visualViewport' in window
  )

  const width = ref(0)
  const height = ref(0)
  const scale = ref(1)
  const offsetTop = ref(0)
  const offsetLeft = ref(0)
  const pageTop = ref(0)
  const pageLeft = ref(0)

  if (supported.value) {
    const updateViewport = () => {
      width.value = window.visualViewport.width
      height.value = window.visualViewport.height
      scale.value = window.visualViewport.scale
      offsetTop.value = window.visualViewport.offsetTop
      offsetLeft.value = window.visualViewport.offsetLeft
      pageTop.value = window.visualViewport.pageTop
      pageLeft.value = window.visualViewport.pageLeft
    }

    // Initialize values
    updateViewport()

    // Listen for viewport changes
    useEventListener(window.visualViewport, 'resize', updateViewport)
    useEventListener(window.visualViewport, 'scroll', updateViewport)
  }

  return {
    supported,
    width,
    height,
    scale,
    offsetTop,
    offsetLeft,
    pageTop,
    pageLeft,
  }
}
