// composables/useOpenGraph.js

export const useOpenGraph = () => {
  const setOpenGraph = ({
    title,
    ogTitle,
    description,
    ogDescription,
    ogImage,
    url,
    twitterCard = 'summary_large_image',
  }) => {
    const meta = []

    if (title) {
      meta.push({ name: 'title', content: title })
    }
    if (ogTitle) {
      meta.push({ property: 'og:title', content: ogTitle })
    }
    if (description) {
      meta.push({ name: 'description', content: description })
    }
    if (ogDescription) {
      meta.push({ property: 'og:description', content: ogDescription })
    }
    if (ogImage) {
      meta.push({ property: 'og:image', content: ogImage })
    }
    if (url) {
      meta.push({ property: 'og:url', content: url })
    }
    if (twitterCard) {
      meta.push({ name: 'twitter:card', content: twitterCard })
    }

    useSeoMeta({
      title,
      meta,
    })
  }

  return {
    setOpenGraph,
  }
}
