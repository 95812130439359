export const useItemStore = defineStore('item', {
  state: () => ({
    items: [],
    mapItems: [],
    selectedItemId: null,
    selectedItemNanoIdFromUrl: null,
    highlightedItemIds: [],
  }),
  getters: {
    highlightedItems: (state) => {
      const items = state.items.filter((item) =>
        state.highlightedItemIds.includes(item.id)
      )

      // TODO: If lenght of items not equal to length of highlightedItemIds, then load missing items from server
      if (items.length > 0) {
        return items
      } else {
        return []
      }
    },
    selectedItem: (state) => {
      const item = state.items.find((item) => item.id === state.selectedItemId)
      if (item) {
        return item
      } else {
        return null
      }
    },
  },
  actions: {
    async createItem(itemMayBeRef) {
      // TODO: Define standard data / error return type
      const item = unref(itemMayBeRef)
      if (!item) {
        return { data: null, error: 'no item passed' }
      }
      const client = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) {
        return 'no user'
      }
      const node = {
        inserted_by: user.value.id,
        point: `POINT(${item.lon} ${item.lat})`,
      }
      const { data } = await client.from('nodes').insert(node).select()

      if (data) {
        const nodeId = data[0].id
        const insertItem = {
          inserted_by: user.value.id,
          label_node_id: nodeId,
        }
        const { data: itemData, error: itemError } = await client
          .from('items')
          .insert(insertItem)
          .select()
          .single()
        if (itemError) {
          return itemError
        }
        if (itemData) {
          const { data: itemDetailKeyNameData } = await client
            .from('details_keys')
            .select('id')
            .eq('key', 'name')
            .single()
          if (itemDetailKeyNameData) {
            const insertItemsDetailsTranslation = {
              inserted_by: user.value.id,
              updated_by: user.value.id,
              item_id: itemData.id,
              details_keys_id: itemDetailKeyNameData.id,
              value: item.name,
            }
            const { error: itemsDetailsTranslationsError } = await client
              .from('items_details_translations')
              .insert(insertItemsDetailsTranslation)

            if (itemsDetailsTranslationsError) {
              return itemsDetailsTranslationsError
            }
            const { data: tagReviewPending } = await client
              .from('tags')
              .select('id')
              .eq('key', 'review')
              .eq('value', 'pending')
            if (tagReviewPending && tagReviewPending.length === 1) {
              const insertItemsTags = {
                inserted_by: user.value.id,
                updated_by: user.value.id,
                item_id: itemData.id,
                tag_id: tagReviewPending[0].id,
              }
              const { error: itemsTagsError } = await client
                .from('items_tags')
                .insert(insertItemsTags)
              if (itemsTagsError) {
                return itemsTagsError
              }
            } else {
              // TODO: when this tag can't be created, escalate or roll back
            }
            if (item.tagId === null && item.tagText.length > 0) {
              const { data: itemDetailKeyNewCategorySuggestionData } =
                await client
                  .from('details_keys')
                  .select('id')
                  .eq('key', 'newCategorySuggestion')
                  .single()
              if (itemDetailKeyNewCategorySuggestionData) {
                const insertItemsDetailsTranslationNewCategorySuggestion = {
                  inserted_by: user.value.id,
                  updated_by: user.value.id,
                  item_id: itemData.id,
                  details_keys_id: itemDetailKeyNewCategorySuggestionData.id,
                  value: item.tagText,
                }
                const {
                  error: itemsDetailsTranslationsNewCategorySuggestionError,
                } = await client
                  .from('items_details_translations')
                  .insert(insertItemsDetailsTranslationNewCategorySuggestion)
                if (itemsDetailsTranslationsNewCategorySuggestionError) {
                  return itemsDetailsTranslationsNewCategorySuggestionError
                }
              }
            }
            if (item.tagId !== null) {
              const { data: tagData } = await client
                .from('tags')
                .select('id')
                .eq('id', item.tagId)
                .eq('is_category', true)
                .single()
              if (tagData) {
                const insertItemsTags = {
                  inserted_by: user.value.id,
                  updated_by: user.value.id,
                  item_id: itemData.id,
                  tag_id: tagData.id,
                }
                await client
                  .from('items_tags')
                  .insert(insertItemsTags)
                  .select()
                  .single()
              }
            }
            await this.fetchItemByNanoId(itemData.nano_id)
            return itemData
          }
        }
      }
    },
    async fetchItemsByBox(minLon, minLat, maxLon, maxLat) {
      if (
        typeof minLon !== 'number' ||
        typeof minLat !== 'number' ||
        typeof maxLon !== 'number' ||
        typeof maxLat !== 'number'
      ) {
        return
      }
      const client = useSupabaseClient()
      const { data, error } = await client.rpc('items_by_box', {
        min_lon: minLon,
        min_lat: minLat,
        max_lon: maxLon,
        max_lat: maxLat,
      })
      if (error) {
        return
      }

      this.mergeDataIntoItems(data)
    },
    async fetchItemById(id) {
      if (!id) {
        return null
      }
      const client = useSupabaseClient()
      const { data, error } = await client.rpc('items_by_id', {
        id_param: id,
      })
      if (error) {
        return
      }
      const itemIndex = this.items.findIndex((item) => item.id === data[0].id)
      if (itemIndex !== -1) this.items[itemIndex] = data[0]
      else this.items.push(data[0])
      return data[0]
    },
    async fetchItemByNanoId(nanoId) {
      if (!nanoId) {
        return null
      }
      const client = useSupabaseClient()
      const { data } = await client.rpc('items_by_nano_id', {
        nano_id_param: nanoId,
      })

      // console.log('store:item:fetchItemByNanoId:data: ', data)
      // TODO: Merge item into items by id
      const newItem = data[0]
      if (!newItem) {
        return null
      }
      const index = this.items.findIndex((item) => item.id === newItem.id)
      if (index === -1) {
        this.items.push(newItem)
      } else {
        this.items[index] = newItem
      }
      // console.log('store:item:fetchItemByNanoId:this.items: ', this.items)
      return newItem
    },
    mergeDataIntoItems(data) {
      if (!data) {
        return
      }
      for (let i = 0; i < data.length; i++) {
        const itemIndex = this.items.findIndex((item) => item.id === data[i].id)
        if (itemIndex !== -1) this.items[itemIndex] = data[i]
        else this.items.push(data[i])
      }
    },
    setHighlightedItemIds(ids) {
      if (ids && ids.length === 1) {
        this.highlightedItemIds = ids
      } else if (ids && ids.length > 1) {
        this.highlightedItemIds = [...new Set(ids)]
      } else {
        this.highlightedItemIds = []
      }
    },
    async getOrFetchItemById(id) {
      if (typeof id !== 'string') {
        return
      }
      if (this.items.length > 0) {
        const item = this.items.find((item) => item.id === id)
        if (item) {
          return item
        } else {
          const newItem = await this.fetchItemById(id)
          return newItem
        }
      } else {
        const newItem = await this.fetchItemById(id)
        return newItem
      }
    },
    async getOrFetchItemByNanoId(nanoId) {
      if (typeof nanoId !== 'string') {
        return
      }
      if (this.items.length > 0) {
        const item = this.items.find((item) => item.nanoId === nanoId)
        if (item) {
          return item
        }
      }

      const newItem = await this.fetchItemByNanoId(nanoId)
      if (newItem) {
        return newItem
      }

      return null
    },
    getItemById(id) {
      if (typeof id !== 'string') {
        return
      }
      if (this.items.length > 0) {
        const item = this.items.find((item) => item.id === id)
        if (item) {
          return item
        }
      }
      return null
    },
    setSelectedItemIdByNanoId(nanoId) {
      if (typeof nanoId !== 'string') {
        return
      }
      if (this.items.length > 0) {
        const item = this.items.find((item) => item.nanoId === nanoId)
        if (item) {
          this.selectedItemId = item.id
        }
      }
    },
    setSelectedItemId(id) {
      this.selectedItemId = id
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useItemStore, import.meta.hot))
}
