export const useDashboardGuideStore = defineStore('dashboardGuide', {
  state: () => ({
    newGuidePanelOpen: false,
    resultPage: 1,
    resultSearchText: '',
    resultShowFilterMenu: false,
  }),
  actions: {},
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDashboardGuideStore, import.meta.hot)
  )
}
