export const useMapPersistedStore = defineStore('mapPersisted', {
  state: () => ({
    selectedStyleId: 5,
    center: null,
    zoom: null,
    pitch: null,
    bearing: null,
  }),
  persist: {
    storage: piniaPluginPersistedstate.cookies({
      maxAge: 7776000, // 90 days in seconds (90 * 24 * 60 * 60)
      sameSite: 'strict',
      path: '/',
    }),
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMapPersistedStore, import.meta.hot))
}
