export const useItem = () => {
  const supabase = useSupabaseClient()

  const getItemFeedText = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    if (!item) {
      return ''
    }
    if (item.details) {
      const detailDescriptions = item.details.filter(
        (detail) => detail.key === 'feedText'
      )
      if (detailDescriptions.length === 1) {
        return detailDescriptions[0].value
      } else if (detailDescriptions.length > 1) {
        const localeDetailDescription = detailDescriptions.find(
          (detail) => detail.locale === locale
        )
        if (localeDetailDescription) {
          return localeDetailDescription.value
        }
      }
    }
    return ''
  }

  const getItemShortDescription = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    if (!item) {
      return ''
    }
    if (item.details) {
      const detailDescriptions = item.details.filter(
        (detail) => detail.key === 'shortDescription'
      )
      if (detailDescriptions.length === 1) {
        return detailDescriptions[0].value
      } else if (detailDescriptions.length > 1) {
        const localeDetailDescription = detailDescriptions.find(
          (detail) => detail.locale === locale
        )
        if (localeDetailDescription) {
          return localeDetailDescription.value
        }
      }
    }
    return ''
  }

  const getItemMapSortKey = (itemMayBeRef) => {
    return 5
  }

  const getItemNewCategorySuggestion = (itemMayBeRef) => {
    const item = unref(itemMayBeRef)
    if (item) {
      const detailNewCategorySuggestions = item.details.filter(
        (detail) => detail.key === 'newCategorySuggestion'
      )
      if (detailNewCategorySuggestions.length > 0) {
        return detailNewCategorySuggestions[0].value
      }
    }
    return null
  }

  const getItemTitle = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    if (!item) {
      return ''
    }
    if (item.details) {
      const detailNames = item.details.filter((detail) => detail.key === 'name')
      if (detailNames.length === 1) {
        return detailNames[0].value
      } else if (detailNames.length > 1) {
        const localeDetailName = detailNames.find(
          (detail) => detail.locale === locale
        )
        if (localeDetailName) {
          return localeDetailName.value
        }
      }
    }
    return ''
  }

  const getMediumOgUrl = (mediumMayBeRef) => {
    const medium = unref(mediumMayBeRef)
    if (!medium) {
      return null
    }
    const { data } = supabase.storage
      .from('public-media')
      .getPublicUrl(medium.path, {
        transform: {
          width: 1200,
          height: 630,
        },
      })
    return data.publicUrl
  }

  const getMediumUrl = (mediumMayBeRef) => {
    const medium = unref(mediumMayBeRef)
    if (!medium) {
      return null
    }
    const { data } = supabase.storage
      .from('public-media')
      .getPublicUrl(medium.path, {
        transform: {
          width: 900,
          height: 600,
        },
      })
    return data.publicUrl
  }

  return {
    getItemFeedText,
    getItemNewCategorySuggestion,
    getItemMapSortKey,
    getItemShortDescription,
    getItemTitle,
    getMediumOgUrl,
    getMediumUrl,
  }
}
