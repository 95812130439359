import { useCookie, useRuntimeConfig } from "#app";
function cookies(options) {
  return {
    getItem: (key) => useCookie(
      key,
      {
        ...options ?? useRuntimeConfig().public.piniaPluginPersistedstate.cookieOptions ?? {},
        decode: decodeURIComponent,
        readonly: true
      }
    ).value,
    setItem: (key, value) => useCookie(
      key,
      {
        ...options ?? useRuntimeConfig().public.piniaPluginPersistedstate.cookieOptions ?? {},
        encode: encodeURIComponent
      }
    ).value = value
  };
}
function localStorage() {
  return {
    getItem: (key) => import.meta.client ? window.localStorage.getItem(key) : null,
    setItem: (key, value) => import.meta.client ? window.localStorage.setItem(key, value) : null
  };
}
function sessionStorage() {
  return {
    getItem: (key) => import.meta.client ? window.sessionStorage.getItem(key) : null,
    setItem: (key, value) => import.meta.client ? window.sessionStorage.setItem(key, value) : null
  };
}
export const storages = {
  cookies,
  localStorage,
  sessionStorage
};
