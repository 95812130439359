<template>
  <main class="relative isolate min-h-full">
    <img
      src="/images/into-void.jpeg"
      alt=""
      class="absolute inset-0 -z-10 h-full w-full object-cover object-top"
    />
    <div class="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
      <p class="text-base font-semibold leading-8 text-white">
        {{ error?.statusCode }}
      </p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
        {{ error?.statusMessage }}
      </h1>
      <p
        v-if="error?.message && error.message !== error.statusMessage"
        class="mt-4 text-base text-white/70 sm:mt-6"
      >
        {{ error.message }}
      </p>

      <div class="mt-10 flex justify-center">
        <NuxtLink
          :to="backPath"
          class="text-sm font-semibold leading-7 text-white hover:text-white/80 transition-colors"
          @click="handleError"
        >
          <span aria-hidden="true">&larr;</span> {{ backText }}
        </NuxtLink>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
interface ErrorLocation {
  file?: string
  line?: number
  column?: number
}

useHead({
  bodyAttrs: {
    class: 'h-full',
  },
  htmlAttrs: {
    class: 'h-full',
  },
})

const { $metric } = useNuxtApp()

const props = defineProps(['error'])
const route = useRoute()

const isMapError = computed(() => {
  const errorPath = props.error?.path || route.fullPath
  return errorPath.startsWith('/map/')
})

const backPath = computed(() => (isMapError.value ? '/map' : '/'))
const backText = computed(() =>
  isMapError.value ? 'Back to map' : 'Back to home'
)

const getErrorLocation = (stack?: string): ErrorLocation => {
  if (!stack) return {}

  // Get the first line after 'Error:' that contains a file path
  const stackLines = stack.split('\n')
  const errorLine = stackLines.find(
    (line) =>
      line.includes('/') && // Has a path
      !line.includes('node_modules/') && // Not from node_modules
      !line.includes('webpack-internal/') // Not from webpack internal
  )

  if (!errorLine) return {}

  // Extract file path and line number using regex
  const match =
    errorLine.match(/\((.+):(\d+):(\d+)\)/) ||
    errorLine.match(/at\s(.+):(\d+):(\d+)/)

  if (!match || !match[1] || !match[2] || !match[3]) return {}

  const [, filePath, lineStr, columnStr] = match
  const line = parseInt(lineStr)
  const column = parseInt(columnStr)

  if (isNaN(line) || isNaN(column)) return {}

  return {
    file: filePath.split('/').slice(-2).join('/'), // Get last 2 parts of path
    line,
    column,
  }
}

const getErrorDetails = (error: any) => {
  // Extract component resolution details from stack
  const componentInfo = error.message?.includes("Couldn't resolve component")
    ? {
        failedComponent: error.message.match(/component "([^"]+)"/)?.[1],
        attemptedPath: error.message.match(/at "([^"]+)"/)?.[1],
      }
    : {}

  // Extract bundle and source map info
  const bundleInfo =
    error.stack
      ?.split('\n')
      ?.find((line) => line.includes('.js:'))
      ?.match(/(\w+\.js):(\d+):(\d+)/) || null

  return {
    // Existing error core
    message: error.message,
    statusCode: error.statusCode,
    fatal: error.fatal,
    stack: error.stack,
    cause: error.cause,

    // Enhanced route information
    path: error?.path || route.fullPath,
    routePattern: route.matched?.[0]?.path,
    params: route.params,
    matchedRoutes: route.matched?.map((m) => ({
      path: m.path,
      name: m.name,
      components: Object.keys(m.components || {}),
    })),

    // Component resolution context
    ...componentInfo,
    resolvedComponents: route.matched?.[0]?.components,

    // Bundle information
    bundleError: bundleInfo
      ? {
          file: bundleInfo[1],
          line: parseInt(bundleInfo[2]),
          column: parseInt(bundleInfo[3]),
        }
      : null,

    // Environment context
    isServer: import.meta.server,
    isClient: import.meta.client,
    timestamp: new Date().toISOString(),

    ...(import.meta.server && {
      headers: useRequestHeaders(),
      url: useRequestURL().href,
      ssrContext: 'available', // This could help identify if SSR context is properly available
    }),

    type: error.statusCode === 404 ? '404_NOT_FOUND' : 'ERROR',
  }
}

// Paths that should not create metrics for 404 errors
const ignoredPaths = [
  '/.aws/',
  '/.env',
  '/.git/',
  '/.well-known/about.php',
  '/.well-known/pki-validation/about.php',
  '/.well-known/pki-validation/cloud.php',
  '/.well-known/pki-validation/xmlrpc.php?p=',
  '/.well-known/traffic-advice',
  '/403.php',
  '/about.php',
  '/about.php7',
  '/admin/',
  '/admin.php',
  '/admin.php7',
  '/admin-post.php',
  '/adminfuns.php7',
  '/ajax-actions.php',
  '/alfa-rex.php7',
  '/alfa-rex2.php7',
  '/alfanew.php',
  '/alfanew.php7',
  '/alfanew2.php7',
  '/app-ads.txt',
  '/backup',
  '/bc',
  '/berlin.php',
  '/bk',
  '/cache-compat.php',
  '/cgi-bin/about.php',
  '/cgi-bin/cloud.php',
  '/cgi-bin/xmlrpc.php?p=',
  '/cloud.php',
  '/content.php',
  '/css/cloud.php',
  '/css/xmlrpc.php?p=',
  '/dropdown.php',
  '/ebs.php7',
  '/files/',
  '/home',
  '/images/about.php',
  '/images/cloud.php',
  '/images/xmlrpc.php?p=',
  '/img/about.php',
  '/img/cloud.php',
  '/img/xmlrpc.php?p=',
  '/javaa.php',
  '/libraries/legacy/updates.php',
  '/libraries/phpmailer/updates.php',
  '/libraries/vendor/updates.php',
  '/main',
  '/new',
  '/old',
  '/public/vendor/',
  '/repeater.php',
  '/ryanz.php',
  '/s3cmd.ini',
  '/sites/',
  '/style.php',
  '/test/',
  '/updates.php',
  '/uploads/',
  '/vendor/laravel-filemanager/',
  '/web/',
  '/website/',
  '/wordpress',
  '/wp',
  '/wp-admin/',
  '/ws.php7',
  '/wsyozanz.php',
  '/xmlrpc.php',
  '/xmlrpc.php?p=',
  '//2018/',
  '//2019/',
  '//2020/',
  '//2021/',
  '//2022/',
  '//2023/',
  '//2024/',
  '//blog/',
  '//cms/',
  '//feed/',
  '//media/wp-includes/',
  '//news/',
  '//shop/',
  '//site/',
  '//sito/',
  '//test/',
  '//web/',
  '//website/',
  '//wordpress/',
  '//wp-includes/',
  '//wp/',
  '//wp1/',
  '//wp2/',
  '//xmlrpc.php',
]

// Check if the current path starts with any of the ignored paths
const shouldIgnoreMetric = computed(() => {
  const currentPath = props.error?.path || route.fullPath
  return (
    props.error?.statusCode === 404 &&
    ignoredPaths.some((path) => currentPath.startsWith(path))
  )
})

// Only send metric if the path should not be ignored
if (!shouldIgnoreMetric.value) {
  $metric({
    key: 'ERROR',
    value: {
      ...getErrorDetails(props.error),
      ...getErrorLocation(props.error.stack),
    },
  })
}

const handleError = () => {
  clearError()
}
</script>

<style>
#__nuxt {
  @apply h-full;
}
</style>
