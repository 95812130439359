export const useGuide = () => {
  const { $log } = useNuxtApp()
  const supabase = useSupabaseClient()

  const getGuideShortDescription = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    if (!item) {
      return ''
    }
    if (item.details) {
      const detailDescriptions = item.details.filter(
        (detail) => detail.key === 'shortDescription'
      )
      if (detailDescriptions.length === 1) {
        return detailDescriptions[0].value
      } else if (detailDescriptions.length > 1) {
        const localeDetailDescription = detailDescriptions.find(
          (detail) => detail.locale === locale
        )
        if (localeDetailDescription) {
          return localeDetailDescription.value
        }
      }
    }
    return ''
  }

  const getGuideTitle = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    if (!item) {
      return ''
    }
    if (item.details) {
      const detailNames = item.details.filter((detail) => detail.key === 'name')
      if (detailNames.length === 1) {
        return detailNames[0].value
      } else if (detailNames.length > 1) {
        const localeDetailName = detailNames.find(
          (detail) => detail.locale === locale
        )
        if (localeDetailName) {
          return localeDetailName.value
        }
      }
    }
    return ''
  }

  const getGuideMediumUrl = (mediumMayBeRef) => {
    const medium = unref(mediumMayBeRef)
    if (!medium) {
      return null
    }
    const { data } = supabase.storage
      .from('public-media')
      .getPublicUrl(medium.path, {
        transform: {
          width: 900,
          height: 600,
        },
      })
    return data.publicUrl
  }

  return {
    getGuideShortDescription,
    getGuideTitle,
    getGuideMediumUrl,
  }
}
