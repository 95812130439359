export const useListStore = defineStore('list', {
  state: () => ({
    lists: [],
    selectedListId: null,
    selectedListNanoIdFromUrl: null,
    sortings: [
      {
        value: 'inserted_at_asc',
        translation: 'insertedAtAsc',
        iconName: 'sun',
        sortOrder: 1,
      },
      {
        value: 'inserted_at_desc',
        translation: 'insertedAtDesc',
        iconName: 'sun',
        sortOrder: 2,
      },
      {
        value: 'name_asc',
        translation: 'nameAsc',
        iconName: 'sun',
        sortOrder: 3,
      },
    ],
    localListsSortings: [],
  }),
  getters: {
    checkinsList: (state) =>
      state.lists.find((list) => list.purpose === 'checkins'),
    likesList: (state) => state.lists.find((list) => list.purpose === 'likes'),
    addableLists: (state) => {
      return state.lists.filter(
        (list) =>
          list.purpose === 'want_to_see' ||
          list.purpose === 'favorites' ||
          list.purpose === 'list'
      )
    },
    selectedList: (state) => {
      const list = state.lists.find((list) => list.id === state.selectedListId)
      if (!list) {
        return null
      } else {
        return list
      }
    },
    shortcutsBarLists: (state) => {
      if (state.lists && state.lists.length > 0) {
        return state.lists.filter((list) => list && list.pinToShortcutsBar)
      }
      return []
    },
    localListSorting: (state) => (listId) => {
      return state.localListsSortings[listId] || null
    },
  },
  actions: {
    async init() {
      const supabase = useSupabaseClient()
      // TODO: This codes is maybe run double, on server and client: https://github.com/nuxt/nuxt/issues/13369#issuecomment-1397305253

      const { data } = await supabase
        .from('lists')
        .select(
          'id, nanoId:nano_id, userId:user_id, name, purpose, icons(name), sortOrder:sort_order, items:lists_items(id, itemId:item_id, insertedAt:inserted_at, item:items(nanoId:nano_id), listsItemsDetailsTranslations:lists_items_details_translations(detailsKeysId:details_keys_id, value, locale)), media:lists_media(insertedAt:inserted_at, sortOrder:sort_order, medium:media(path)), visibility, sorting, pinToShortcutsBar:pin_to_shortcuts_bar, tagFilters:lists_tags_filter(tagId:tag_id), guidesLists:guides_lists(guides:guides(nanoId:nano_id))'
        )
        .order('sort_order', { ascending: true })

      this.lists = data
    },
    clearSelectedList() {
      this.selectedListId = null
    },
    async createList(nameMayBeRef, purposeMayBeRef, iconIdMayBeRef) {
      const name = unref(nameMayBeRef)
      const purpose = unref(purposeMayBeRef)
      const iconId = unref(iconIdMayBeRef)

      const client = useSupabaseClient()
      const user = useSupabaseUser()

      if (!user.value) return
      const { data, error } = await client
        .from('lists')
        .insert({
          name,
          purpose,
          user_id: user.value.id,
          icon_id: iconId,
        })
        .select('id')
        .single()
      if (error) {
        // console.error('store:list:createList:error: ', error)
        return
      }
      await this.init()
      return data
    },
    async deleteList(id) {
      const user = useSupabaseUser()
      const client = useSupabaseClient()
      if (!user.value) return 'User not logged in'
      const list = this.getListById(id)
      if (!list) return 'List not found'
      if (list.userId !== user.value.id) return 'User not authorized'
      if (list.items) {
        const { error } = await client
          .from('lists_items')
          .delete()
          .eq('list_id', id)
        if (error) {
          // console.error('store:list:deleteList:listsitems:error: ', error)
          return error
        }
      }
      // delete list details
      // delete list images
      // delete permissions
      // delete list
      const { error } = await client.from('lists').delete().eq('id', id)
      if (error) {
        // console.error('store:list:deleteList:lists:error: ', error)
        return error
      }
      const index = this.lists.findIndex((list) => list.id === id)
      this.lists.splice(index, 1)
      return null
    },
    async fetchListByNanoId(nanoId) {
      if (!nanoId) {
        return null
      }
      const client = useSupabaseClient()

      // Make sure this fetch is only run on server or client, but not twice
      const { data } = await client
        .from('lists')
        .select(
          'id, nanoId:nano_id, userId:user_id, name, purpose, icons(name), sortOrder:sort_order, items:lists_items(id, itemId:item_id, insertedAt:inserted_at, item:items(nanoId:nano_id), listsItemsDetailsTranslations:lists_items_details_translations(detailsKeysId:details_keys_id, value, locale)), media:lists_media(insertedAt:inserted_at, sortOrder:sort_order, medium:media(path)), visibility, sorting, pinToShortcutsBar:pin_to_shortcuts_bar, tagFilters:lists_tags_filter(tagId:tag_id), guidesLists:guides_lists(guides:guides(nanoId:nano_id))'
        )
        .eq('nano_id', nanoId)
        .single()

      // merge fetched list into store
      const list = this.lists.find((list) => list.nanoId === nanoId)
      if (list) {
        Object.assign(list, data)
      } else {
        this.lists.push(data)
      }
      return data
    },
    getListById(id) {
      return this.lists.find((list) => list.id === id)
    },
    async getOrFetchListByNanoId(nanoId) {
      if (typeof nanoId !== 'string') {
        return
      }
      if (this.lists.length > 0) {
        const list = this.lists.find((list) => list.nanoId === nanoId)
        if (list) {
          return list
        }
      } else {
        const newList = await this.fetchListByNanoId(nanoId)
        return newList
      }
      return null
    },
    isItemInAnyAddableList(itemIdMayBeRef) {
      const itemId = unref(itemIdMayBeRef)
      return this.addableLists.some((list) =>
        list.items.some((listsItem) => listsItem.itemId === itemId)
      )
    },
    isItemInList(itemIdMayBeRef, listIdMayBeRef) {
      const itemId = unref(itemIdMayBeRef)
      const listId = unref(listIdMayBeRef)
      const list = this.lists.find((list) => list.id === listId)
      if (!list) return false
      return list.items.some((listsItem) => listsItem.itemId === itemId)
    },
    setLocalListsSortings(listId, sortingValue) {
      if (!this.localListsSortings[listId]) {
        this.localListsSortings = {
          ...this.localListsSortings,
          [listId]: sortingValue,
        }
      } else {
        this.localListsSortings[listId] = sortingValue
      }
    },
    setSelectedListByNanoId(nanoId) {
      const list = this.lists.find((list) => list.nanoId === nanoId)
      this.selectedListId = list.id
    },
    async toggleListsItem(itemIdMayBeRef, listIdMayBeRef) {
      const itemId = unref(itemIdMayBeRef)
      const listId = unref(listIdMayBeRef)

      const client = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return

      const isItemInList = this.isItemInList(itemId, listId)

      if (isItemInList) {
        // remove
        const listsItemsId = this.lists
          .find((list) => list.id === listId)
          .items.find((listsItem) => listsItem.itemId === itemId).id
        const { error } = await client
          .from('lists_items')
          .delete()
          .eq('id', listsItemsId)
      } else {
        // add
        const { error } = await client.from('lists_items').insert({
          list_id: listId,
          user_id: user.value.id,
          item_id: itemId,
        })
      }
      await this.init()
    },
    async updateList(listIdMayBeRef, nameMayBeRef, pinToShortcutsBarMayBeRef) {
      const listId = unref(listIdMayBeRef)
      const name = unref(nameMayBeRef)
      const pinToShortcutsBar = unref(pinToShortcutsBarMayBeRef)

      const client = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return
      if (!listId) return

      const list = this.getListById(listId)
      if (!list) return
      if (list.userId !== user.value.id) return

      const { data, error } = await client
        .from('lists')
        .update({
          name,
          pin_to_shortcuts_bar: pinToShortcutsBar,
        })
        .eq('id', listId)
        .select(
          'id, nanoId:nano_id, userId:user_id, name, purpose, icons(name), sortOrder:sort_order, items:lists_items(id, itemId:item_id, insertedAt:inserted_at, item:items(nanoId:nano_id), listsItemsDetailsTranslations:lists_items_details_translations(detailsKeysId:details_keys_id, value, locale)), media:lists_media(insertedAt:inserted_at, sortOrder:sort_order, medium:media(path)), visibility, sorting, pinToShortcutsBar:pin_to_shortcuts_bar, tagFilters:lists_tags_filter(tagId:tag_id), guidesLists:guides_lists(guides:guides(nanoId:nano_id))'
        )
        .single()
      const index = this.lists.findIndex((list) => list.id === listId)
      this.lists[index] = data
      return error
    },
    async updateListProperty(listIdMayBeRef, propertyMayBeRef, valueMayBeRef) {
      const listId = unref(listIdMayBeRef)
      const property = unref(propertyMayBeRef)
      const value = unref(valueMayBeRef)

      if (!property) return

      let newProperty = null
      let newValue = null
      if (property === 'sorting') {
        newProperty = 'sorting'
        newValue = value
      }

      if (!newProperty) return

      const client = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return
      if (!listId) return

      const list = this.getListById(listId)
      if (!list) return
      if (list.userId !== user.value.id) return

      const { data, error } = await client
        .from('lists')
        .update({
          [newProperty]: newValue,
        })
        .eq('id', listId)
        .select(
          'id, nanoId:nano_id, userId:user_id, name, purpose, icons(name), sortOrder:sort_order, items:lists_items(id, itemId:item_id, insertedAt:inserted_at, item:items(nanoId:nano_id), listsItemsDetailsTranslations:lists_items_details_translations(detailsKeysId:details_keys_id, value, locale)), media:lists_media(insertedAt:inserted_at, sortOrder:sort_order, medium:media(path)), visibility, sorting, pinToShortcutsBar:pin_to_shortcuts_bar, tagFilters:lists_tags_filter(tagId:tag_id), guidesLists:guides_lists(guides:guides(nanoId:nano_id))'
        )
        .single()
      const index = this.lists.findIndex((list) => list.id === listId)
      this.lists[index] = data
      return error
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useListStore, import.meta.hot))
}
