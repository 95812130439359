import { addDays, formatISO, parseISO } from 'date-fns'

export const useProfileStore = defineStore('profile', {
  state: () => ({
    currentUser: {},
    profiles: [],
    shareProOrigin: {},
    supabaseUser: {},
  }),
  getters: {
    getProfileByUserId: (state) => {
      return (userIdMayBeRef) => {
        const userId = unref(userIdMayBeRef)
        return state.profiles.find((profile) => profile.id === userId)
      }
    },
    isAuthenticated: (state) => {
      if (
        state.supabaseUser &&
        state.supabaseUser.aud &&
        state.supabaseUser.aud === 'authenticated'
      ) {
        return true
      } else {
        return false
      }
    },
    isQuoPro: (state) => {
      if (state.currentUser && state.currentUser.memberNumber) {
        return true
      } else {
        return false
      }
    },
    profile: (state) => {
      return {
        avatarUrl: state.currentUser?.avatarUrl,
        fullName: state.currentUser?.fullName,
        username: state.currentUser?.username,
        visibility: state.currentUser?.visibility,
        memberNumber: state.currentUser?.memberNumber,
        memberSince: state.currentUser?.proSince,
        userIdProShareOrigin: state.currentUser?.userIdProShareOrigin,
        plan: state.currentUser?.plan,
      }
    },
    status: (state) => {
      if (
        state.supabaseUser &&
        state.supabaseUser.aud &&
        state.supabaseUser.aud === 'authenticated'
      ) {
        return state.supabaseUser.aud
      } else {
        return 'unauthenticated'
      }
    },
  },
  actions: {
    clearUser() {
      this.supabaseUser = {}
      this.currentUser = {}
      this.profiles = []
      this.shareProOrigin = {}
    },
    async createShareProOrigin() {
      const client = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return
      if (this.shareProOrigin.id !== undefined) return

      // create sharePro
      const shareProOriginData = {
        user_id_origin: user.value.id,
        expired_at: formatISO(addDays(new Date(), 3)),
      }
      const { data: newShareProOrigin } = await useAsyncData(
        'newShareProOrigin',
        async () => {
          const { data, error } = await client
            .from('profiles_pros_shares')
            .insert(shareProOriginData)
            .select()
          if (error) throw error
          return data
        }
      )
      if (newShareProOrigin.value && newShareProOrigin.value.length === 1) {
        const returnShareProOrigin = {
          id: newShareProOrigin.value[0].id,
          userIdDestination: newShareProOrigin.value[0].user_id_destination,
          sharecodeNanoId: newShareProOrigin.value[0].sharecode_nano_id,
          acceptedAt: parseISO(newShareProOrigin.value[0].accepted_at),
          expiredAt: parseISO(newShareProOrigin.value[0].expired_at),
        }
        this.shareProOrigin = returnShareProOrigin
        return returnShareProOrigin
      }
      return null
    },
    async fetchCurrentUser() {
      const supabase = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return
      const { data } = await supabase.rpc('get_current_user')
      // console.log('store:profile:fetchCurrentUser:data')
      if (data && data.length === 1) {
        this.currentUser = data[0]
      }
    },
    async fetchProfiles() {
      // console.log('store:profile:fetchProfiles:begin')
      const supabase = useSupabaseClient()
      // console.log('store:profile:fetchProfiles:profiles:fetching')
      const { data } = await supabase.rpc('visible_profiles_for_user')
      if (data) {
        // console.log(
        //   'store:profile:fetchProfiles:profiles:data:loaded:length',
        //   data.length
        // )
        // TODO: assign data by index
        this.profiles = data
      }
    },
    fetchSupabaseUser() {
      const user = useSupabaseUser()
      this.supabaseUser = user.value
    },
    async fetchShareProOrigin() {
      const supabase = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return
      const { data } = await supabase
        .from('profiles_pros_shares')
        .select(
          'id, userIdDestination: user_id_destination, sharecodeNanoId: sharecode_nano_id, acceptedAt: accepted_at, expiredAt: expired_at'
        )
        .eq('user_id_origin', user.value.id)

      if (data && data.length === 1) {
        this.shareProOrigin = data[0]
      }
    },
    async getShareProOriginBySharecodeNanoId(sharecodeNanoId) {
      const client = useSupabaseClient()
      const user = useSupabaseUser()
      if (!user.value) return
      if (!sharecodeNanoId) return
      if (sharecodeNanoId.length !== 8) return
      // TODO: This codes is maybe run double, on server and client: https://github.com/nuxt/nuxt/issues/13369#issuecomment-1397305253
      const { data: proSharesByNanoIdData } = await useAsyncData(
        'proSharesByNanoIdData',
        async () => {
          const { data, error } = await client.rpc('pro_share_by_nano_id', {
            param_nano_id: sharecodeNanoId,
          })
          if (error) {
            throw error
          }
          return data
        }
      )
      if (
        proSharesByNanoIdData.value &&
        proSharesByNanoIdData.value.length === 1
      ) {
        const newProSharesByNanoIdData = {
          id: proSharesByNanoIdData.value[0].id,
          userIdOrigin: proSharesByNanoIdData.value[0].user_id_origin,
          userIdDestination: proSharesByNanoIdData.value[0].user_id_destination,
          sharecodeNanoId: proSharesByNanoIdData.value[0].sharecode_nano_id,
          expiredAt: parseISO(proSharesByNanoIdData.value[0].expired_at),
          revokedAt: parseISO(proSharesByNanoIdData.value[0].revoked_at),
          acceptedAt: parseISO(proSharesByNanoIdData.value[0].accepted_at),
          username: proSharesByNanoIdData.value[0].username,
          fullName: proSharesByNanoIdData.value[0].full_name,
          avatarUrl: proSharesByNanoIdData.value[0].avatar_url,
          createdAt: parseISO(proSharesByNanoIdData.value[0].inserted_at),
          memberNumber: proSharesByNanoIdData.value[0].member_number,
        }
        return newProSharesByNanoIdData
      } else {
        return null
      }
    },
    async signOut() {
      const supabase = useSupabaseClient()
      await supabase.auth.signOut()
      this.clearUser()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
}
