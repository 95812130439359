export const useMentionStore = defineStore('mention', {
  state: () => ({
    mentions: [],
  }),
  getters: {
    latestMentionByItemId: (state) => (itemId) => {
      const mentions = state.mentions.filter(
        (mention) => mention.itemId === itemId
      )
      if (mentions.length === 0) return null
      // TODO: If there will be more than one brick, then we need to sort by brick insertedAt first
      mentions.sort((a, b) => {
        return new Date(b.insertedAt) - new Date(a.insertedAt)
      })
      return mentions[0]
    },
    getMentionsByItemId: (state) => {
      return (itemIdMayBeRef) => {
        const itemId = unref(itemIdMayBeRef)
        return state.mentions.filter((mention) => mention.itemId === itemId)
      }
    },
    getMentionsByUserId: (state) => {
      return (userIdMayBeRef) => {
        const userId = unref(userIdMayBeRef)
        return state.mentions.filter((mention) => mention.userId === userId)
      }
    },
  },
  actions: {
    async fetchMentions() {
      const supabase = useSupabaseClient()
      const { data } = await supabase
        .from('mentions_items_bricks')
        .select(
          'id, insertedAt:inserted_at, userId:user_id, itemId:item_id, bricks:bricks_translations(id, note:value, userId:user_id, insertedAt:inserted_at)'
        )
      if (data) {
        this.mentions = data
      }
    },
    async createMention(payload) {
      const headers = useRequestHeaders(['cookie'])
      const { data, error } = await useFetch('/api/mentions/', {
        method: 'POST',
        body: payload,
        headers,
      })
      if (error.value) {
        console.error('store:mention:createMention: error: ', error.value)
        return
      }
      if (data.value) {
        await this.fetchMentions()
        return data.value
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMentionStore, import.meta.hot))
}
