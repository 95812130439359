export const useGeoEntityStore = defineStore('geoEntity', {
  state: () => ({
    addressesTrees: [],
    geoEntities: [],
  }),
  getters: {
    addressesTreesWithParentsAndNoGuides: (state) => {
      const getParentChain = (address) => {
        const parents = []
        let currentAddress = address

        while (currentAddress?.parent_id) {
          const parentAddress = state.addressesTrees.find(
            (addr) => addr.id === currentAddress.parent_id
          )

          const parentName =
            parentAddress?.geoEntity?.nameEn ?? parentAddress?.geoEntity?.name
          if (parentName) {
            parents.unshift(parentName)
          }

          currentAddress = parentAddress
        }

        return parents.join(' > ')
      }

      return state.addressesTrees
        .filter((address) => !address.guidesAddressesTrees?.length)
        .map((address) => ({
          ...address,
          name: address.geoEntity?.nameEn ?? address.geoEntity?.name ?? '',
          parentChain: getParentChain(address),
        }))
        .sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        )
    },
  },
  actions: {
    async fetchAddressesTrees() {
      const supabase = useSupabaseClient()
      const { data } = await supabase.from('addresses_trees').select(`
          id, 
          type,
          parent_id,
          geoEntity:geo_entity_id(
            id, 
            name,
            nameEn:name_en
          ),
          guidesAddressesTrees:guides_addresses_trees(guideId:guide_id)
        `)
      if (data) {
        this.addressesTrees = data
      }
    },
    async fetchGeoEntities() {
      const supabase = useSupabaseClient()
      const { data } = await supabase
        .from('geo_entities')
        .select('id, iso31661Alpha2:iso_3166_1_alpha_2, nameEn:name_en')
        .not('iso_3166_1_alpha_2', 'is', null)
      // console.log('store:geoEntity: fetchGeoEntities: data: ', data.length)
      if (data) {
        this.geoEntities = data
      }
    },
    getAddressesTreeById(id) {
      return this.addressesTrees.find((address) => address.id === id)
    },
    getGeoEntityById(id) {
      return this.geoEntities.find((geoEntity) => geoEntity.id === id)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGeoEntityStore, import.meta.hot))
}
