export const useGuideStore = defineStore('guide', {
  state: () => ({
    guides: [],
    selectedGuideNanoIdUnsecure: null,
    selectedGuideDetailKeyInsecure: null,
  }),
  getters: {
    selectedGuide: (state) => {
      if (
        state.selectedGuideNanoIdUnsecure &&
        state.selectedGuideNanoIdUnsecure.length === 8
      ) {
        const guide = state.guides.find(
          (guide) => guide.nanoId === state.selectedGuideNanoIdUnsecure
        )
        if (guide) {
          return guide
        }
      }
      return null
    },
  },
  actions: {
    async fetchGuideByNanoId(nanoId) {
      if (!nanoId) {
        return null
      }
      const client = useSupabaseClient()

      // Make sure this fetch is only run on server or client, but not twice
      const { data } = await client
        .rpc('guides_by_nano_id', {
          nano_id_param: nanoId,
        })
        .single()
      // console.log('stores/guide.js: fetchGuideByNanoId: data', data)
      const guide = this.guides.find((guide) => guide.nanoId === nanoId)
      if (guide) {
        Object.assign(guide, data)
      } else {
        this.guides.push(data)
      }
      // console.log(
      //   'stores/guide.js: fetchGuideByNanoId: this.guides',
      //   this.guides
      // )
      return data
    },
    async getOrFetchGuideByNanoId(nanoId) {
      if (typeof nanoId !== 'string') {
        return
      }
      if (this.guides.length > 0) {
        const guide = this.guides.find((guide) => guide.nanoId === nanoId)
        if (guide) {
          return guide
        }
      } else {
        const newGuide = await this.fetchGuideByNanoId(nanoId)
        return newGuide
      }
      return null
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGuideStore, import.meta.hot))
}
