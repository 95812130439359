export const useAppStore = defineStore('app', {
  state: () => ({
    agent: null,
    deviceId: null,
    version: '2.1.0-alpha.9',
    releaseDate: '2025-01-08',
    showDebugInfo: false,
  }),
  getters: {
    isQuoSwift() {
      return this.agent && this.agent.includes('QuoSwift')
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
