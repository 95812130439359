export const useTagStore = defineStore('tag', {
  state: () => ({
    tags: [],
  }),
  actions: {
    async fetchTags() {
      const supabase = useSupabaseClient()

      const { data } = await supabase
        .from('tags')
        .select(
          'id, key, value, short, iconId:icon_id, color, bgColor:bg_color, isCategory:is_category, mapZOrder:map_z_order, mapLayer:map_layer, tagsTranslations:tags_translations(locales(code), key, value, short)'
        )
      if (data) {
        this.tags = data
      }
    },
    getTagById(id) {
      return this.tags.find((tag) => tag.id === id)
    },
    getTagByKeyValue(key, value) {
      return this.tags.find((tag) => tag.key === key && tag.value === value)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTagStore, import.meta.hot))
}
