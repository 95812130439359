export const useTag = () => {
  const { $log } = useNuxtApp()

  const tagStore = useTagStore()
  const { tags } = storeToRefs(tagStore)
  const iconStore = useIconStore()

  const getCategoryTags = (localeMayBeRef) => {
    const locale = unref(localeMayBeRef)
    const categoryTags = tags.value.filter((tag) => tag.isCategory)
    const fullTags = []
    for (const tag of categoryTags) {
      let newTag = {}
      newTag = Object.assign({}, tag)
      const translation = getTagTranslations(newTag, locale)
      if (translation) {
        newTag = Object.assign({}, newTag, translation)
      }
      const icon = iconStore.getIconById(newTag.iconId)
      if (icon) {
        newTag.iconName = icon.name
      } else {
        newTag.iconName = null
      }
      fullTags.push(newTag)
    }
    return fullTags
  }

  const getItemIcon = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    const mainTag = getItemMainTag(item, locale)
    if (mainTag) {
      const icon = iconStore.getIconById(mainTag.iconId)
      if (icon) {
        const itemIcon = {
          name: icon.name,
          color: mainTag.color,
          bgColor: mainTag.bgColor,
        }
        return itemIcon
      }
    }
    // create a default icon
    const itemIcon = {
      name: 'dot',
      color: 'no-tag-foreground',
      bgColor: 'no-tag-background',
    }
    return itemIcon
  }

  const getItemMainTag = (itemMayBeRef, localeMayBeRef) => {
    const locale = unref(localeMayBeRef)
    const item = unref(itemMayBeRef)
    const tags = getItemSubTitleTags(item, locale, 'getItemMainTag')
    if (tags && tags.length) {
      return tags[0]
    } else {
      return null
    }
  }

  const getItemMainTagId = (itemMayBeRef) => {
    const item = unref(itemMayBeRef)
    if (item && item.tags && item.tags.length) {
      const categoryTags = item.tags.filter((tag) => tag.isCategory)
      if (categoryTags && categoryTags.length && categoryTags.length === 1) {
        return categoryTags[0].id
      } else if (
        categoryTags &&
        categoryTags.length &&
        categoryTags.length > 1
      ) {
        const categoryTag = [...categoryTags].sort(
          (a, b) => a.sortOrder - b.sortOrder
        )[0]
        return categoryTag.id
      }
    }
    return null
  }

  const getItemMapLayer = (itemMayBeRef, localeMayBeRef) => {
    const item = unref(itemMayBeRef)
    const locale = unref(localeMayBeRef)
    if (item && item.tags && item.tags.length) {
      const mainTag = getItemMainTag(item, locale)
      if (mainTag) {
        return mainTag.mapLayer
      }
    }
    return 'low'
  }

  const getItemSubTitleTags = (itemMayBeRef, localeMayBeRef, caller) => {
    const locale = unref(localeMayBeRef)
    const item = unref(itemMayBeRef)
    $log(
      'composables:useTag:getItemSubTitleTags:caller: ',
      caller,
      ', item:',
      item
    )
    if (item && item.tags && item.tags.length) {
      const fullTags = []
      for (const tag of item.tags) {
        if (tag.isCategory) {
          const newTag = {
            ...{ sortOrder: tag.sortOrder },
            ...getTagById(tag.id),
          }

          // newTag.sortOrder = tag.sortOrder
          const translation = getTagTranslations(newTag, locale)
          if (translation) {
            // TODO: why can't I do this? newTag = Object.assign({}, newTag, translation)
            newTag.key = translation.key
            newTag.value = translation.value
            newTag.short = translation.short
          }
          fullTags.push(newTag)
        }
      }

      return [...fullTags].sort((a, b) => a.sortOrder - b.sortOrder)
      // return fullTags
    }
    return null
  }

  const getTagById = (id) => {
    return tags.value.find((tag) => tag.id === id)
  }

  const getTagByKeyValue = (keyMayBeRef, valueMayBeRef, localeMayBeRef) => {
    const key = unref(keyMayBeRef)
    const value = unref(valueMayBeRef)
    const locale = unref(localeMayBeRef)
    if (tags.value && tags.value.length && key && value && locale) {
      const tag = tags.value.find(
        (tag) => tag.key === key && tag.value === value
      )
      let newTag = {}
      if (tag) {
        const translation = getTagTranslations(tag, locale)
        if (translation) {
          newTag = Object.assign({}, tag, translation)
        } else {
          newTag = Object.assign({}, tag)
        }
        return newTag
      }
    }
    return null
  }

  const getTagIconNameByTagId = (tagIdMayBeRef) => {
    const tagId = unref(tagIdMayBeRef)
    if (tagId) {
      const tag = getTagById(tagId)
      if (tag) {
        const icon = iconStore.getIconById(tag.iconId)
        if (icon) {
          return icon.name
        }
      }
    }
    return null
  }

  const getTagsByKey = (tagsMayBeRef, keyMayBeRef, localeMayBeRef) => {
    const tags = unref(tagsMayBeRef)
    // $log('composables:useTag:getTagsByKey:tags', tags)
    const key = unref(keyMayBeRef)
    const locale = unref(localeMayBeRef)
    if (tags && tags.length && key && locale) {
      const fullTags = []
      for (const tag of tags) {
        const fullTag = getTagById(tag.id)
        // $log('composables:useTag:getTagsByKey:fullTag', fullTag)
        if (fullTag.key !== key) {
          continue
        }
        let newTag = {}
        newTag.sortOrder = tag.sortOrder
        const translation = getTagTranslations(fullTag, locale)
        if (translation) {
          newTag = Object.assign({}, fullTag, translation)
        } else {
          newTag = Object.assign({}, fullTag)
        }
        // $log('composables:useTag:getTagsByKey:newTag', newTag)
        fullTags.push(newTag)
      }
      const fullTagsSorted = [...fullTags].sort((a, b) => {
        return a.sortOrder - b.sortOrder || a.short.localeCompare(b.short)
      })
      return fullTagsSorted
    }
    return null
  }

  const getTagTranslations = (tagMayBeRef, localeMayBeRef) => {
    const tag = unref(tagMayBeRef)
    const locale = unref(localeMayBeRef)
    if (!tag) {
      return null
    }
    if (tag && tag.tagsTranslations && tag.tagsTranslations.length) {
      const tagTranslation = tag.tagsTranslations.find(
        (translation) => translation.locales.code === locale
      )
      if (tagTranslation) {
        return tagTranslation
      } else if (locale !== 'en') {
        const tagTranslation = tag.tagsTranslations.find(
          (translation) => translation.locales.code === 'en'
        )
        if (tagTranslation) {
          return tagTranslation
        }
      }
      return null
    }
  }

  const hasSegmentTagsBySegmentKey = (tagsMayBeRef, segmentKeyMayBeRef) => {
    const tags = unref(tagsMayBeRef)
    const segmentKey = unref(segmentKeyMayBeRef)
    const segmentStore = useSegmentStore()
    if (!tags || !segmentKey) {
      return false
    }
    const segment = segmentStore.getSegmentByKey(segmentKey)
    if (!segment) {
      return false
    }
    const segmentTags = tags.filter((tag) => tag.segmentId === segment.id)
    if (segmentTags && segmentTags.length) {
      return true
    }
    return false
  }

  return {
    getCategoryTags,
    getItemIcon,
    getItemMainTag,
    getItemMainTagId,
    getItemMapLayer,
    getItemSubTitleTags,
    getTagByKeyValue,
    getTagIconNameByTagId,
    getTagsByKey,
    hasSegmentTagsBySegmentKey,
  }
}
