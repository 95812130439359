export const useMap = () => {
  const { $log, $metric } = useNuxtApp()

  const localePath = useLocalePath()

  const addMouseEvents = (map, layerId) => {
    map
      .on('click', layerId, (e) => {
        $log(
          'composables:useMap:addMouseEvents:localePath',
          localePath({
            name: 'map-place-nanoId-slug',
            params: { nanoId: e.features[0].properties.nanoId },
          })
        )
        $metric({
          key: 'MAP_ITEM_CLICK',
          itemId: e.features[0].properties.id,
        })
        return navigateTo(
          localePath({
            name: 'map-place-nanoId-slug',
            params: { nanoId: e.features[0].properties.nanoId },
          })
        )
      })
      .on('mouseenter', layerId, () => {
        map.getCanvas().style.cursor = 'pointer'
      })
  }

  /**
   * Calculates the haversine distance between point A, and B.
   * @param {number[]} latlngA [lat, lng] point A
   * @param {number[]} latlngB [lat, lng] point B
   * @param {boolean} isMiles If we are using miles, else km.
   */
  const haversineDistance = ([lat1, lon1], [lat2, lon2], isMiles = false) => {
    const toRadian = (angle) => (Math.PI / 180) * angle
    const distance = (a, b) => (Math.PI / 180) * (a - b)
    const RADIUS_OF_EARTH_IN_KM = 6371

    const dLat = distance(lat2, lat1)
    const dLon = distance(lon2, lon1)

    lat1 = toRadian(lat1)
    lat2 = toRadian(lat2)

    // Haversine Formula
    const a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.asin(Math.sqrt(a))

    let finalDistance = RADIUS_OF_EARTH_IN_KM * c

    if (isMiles) {
      finalDistance /= 1.60934
    }

    return finalDistance
  }

  const initSourceAndLayer = (params) => {
    const map = params.map
    const sourceId = params.sourceId
    const sourceDataType = params.sourceDataType
    const layerId = params.layerId
    const layerType = params.layerType
    const layout = params.layout
    const paint = params.paint
    const positionBelowLayer = params.positionBelowLayer
    const minzoom = params.minzoom
    const maxzoom = params.maxzoom
    const slot = params.slot
    const selectedStyle = params.selectedStyle
    $log('composables:useMap:initSourceAndLayer:sourceId', sourceId)
    if (!map || !sourceId || !sourceDataType || !layerId || !layerType) return

    const source = {
      type: 'geojson',
      data: {
        type: sourceDataType,
        features: [],
      },
    }
    $log('composables:useMap:initSourceAndLayer:addSource', source)
    const existingSource = map.getSource(sourceId)
    if (!existingSource) {
      map.addSource(sourceId, source)
    }

    const layer = {
      id: layerId,
      type: layerType,
      source: sourceId,
      minzoom: minzoom !== null ? minzoom : 0,
      maxzoom: maxzoom !== null ? maxzoom : 24,
      layout,
      paint,
    }
    $log('composables:useMap:initSourceAndLayer:selectedStyle', selectedStyle)
    if (selectedStyle.value.useMapboxStandardStyle && slot) {
      layer.slot = slot
    }

    let positionLayer = null
    if (!selectedStyle.value.useMapboxStandardStyle && positionBelowLayer) {
      positionLayer = positionBelowLayer
    }
    $log('composables:useMap:initSourceAndLayer:addLayer', layer)
    map.addLayer(layer, positionLayer)
  }

  const setDataForSource = (
    map,
    sourceId,
    sourceDataType,
    itemDataMayBeRef
  ) => {
    const itemData = unref(itemDataMayBeRef)
    if (!map || !sourceId || !sourceDataType || !itemData) return

    const sourceData = {
      type: sourceDataType,
      features: [],
    }

    for (const item of itemData) {
      const feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.lon, item.lat],
        },
        properties: {
          id: item.id,
          title: item.title,
          subtitle: item.subtitle,
          nanoId: item.nanoId,
          tagId: item.tagId,
          iconImageDot: item.iconImageDot,
          iconImageSymbol: item.iconImageSymbol,
          iconImagePinSymbol: item.iconImagePinSymbol,
          symbolSortKey: item.symbolSortKey,
          textColor: item.textColor,
        },
      }
      sourceData.features.push(feature)
    }
    if (map.getSource(sourceId)) {
      map.getSource(sourceId).setData(sourceData)
    }
  }

  return {
    addMouseEvents,
    haversineDistance,
    initSourceAndLayer,
    setDataForSource,
  }
}
