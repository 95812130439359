import { parseISO } from 'date-fns'
import { useSorted } from '@vueuse/core'

export const useList = () => {
  const { getItemTitle } = useItem()

  const getListResult = async (
    listMayBeRef,
    sortingMayBeRef,
    localeMayBeRef
  ) => {
    const list = unref(listMayBeRef)
    const sorting = unref(sortingMayBeRef)
    const locale = unref(localeMayBeRef)

    if (!list?.items) return null

    const fetchItems = async (items, type) => {
      const itemStore = useItemStore()
      const promises = items.map(async (item) => {
        const fetchedItem = await itemStore.getOrFetchItemById(
          item.itemId || item.item.nanoId
        )
        if (!fetchedItem) return null
        return {
          ...fetchedItem,
          type,
          insertedAt: parseISO(item.insertedAt),
          title: getItemTitle(fetchedItem, locale),
          ...(type === 'mentions' && { bricks: item.bricks }),
          ...(type !== 'mentions' && { isUnlike: item.isUnlike }),
          ...(type === 'item' && {
            sortOrder: item.sortOrder,
          }),
          ...(type === 'guides' && {
            detailsTranslations: item.listsItemsDetailsTranslations?.[0]?.value,
          }),
        }
      })
      return (await Promise.all(promises)).filter(Boolean)
    }

    let items
    switch (list.purpose) {
      case 'likes':
        items = await fetchItems(
          storeToRefs(useLikeStore()).likes.value,
          'likes'
        )
        break
      case 'mentions':
        items = await fetchItems(
          useMentionStore().getMentionsByUserId(useSupabaseUser().value.id),
          'mentions'
        )
        break
      case 'checkins':
        items = await fetchItems(
          storeToRefs(useCheckinStore()).checkins.value,
          'checkins'
        )
        break
      case 'guides':
        items = await fetchItems(list.items, 'guides')
        break
      default:
        items = await fetchItems(list.items, 'item')
    }

    const sortFunctions = {
      inserted_at_asc: (a, b) => a.insertedAt - b.insertedAt,
      inserted_at_desc: (a, b) => b.insertedAt - a.insertedAt,
      name_asc: (a, b) => {
        const titleA = a.title || ''
        const titleB = b.title || ''
        return titleA.localeCompare(titleB, locale)
      },
    }

    return useSorted(
      items,
      sortFunctions[sorting] || sortFunctions.inserted_at_desc
    )
  }

  const getListSubTitle = (listMayBeRef) => {
    const list = unref(listMayBeRef)
    if (!list) {
      return null
    }
    const { t } = useI18n()
    const purpose = list.purpose
    const translation = t(`lists.defaultTitles.purposes.${purpose}`)
    return translation
  }

  const getListTitle = (title, purpose = null, t) => {
    if (title) {
      return title
    } else if (purpose) {
      const translation = t(`lists.defaultTitles.purposes.${purpose}`)
      return translation
    } else {
      return 'List'
    }
  }

  const getMediumUrl = (mediumMayBeRef) => {
    const medium = unref(mediumMayBeRef)
    if (!medium) {
      return null
    }
    const client = useSupabaseClient()
    const { data } = client.storage
      .from('public-media')
      .getPublicUrl(medium.medium.path, {
        transform: {
          width: 900,
          height: 400,
        },
      })
    return data.publicUrl
  }

  const isListAvailable = (purpose, isQuoPro) => {
    if (isQuoPro) return true
    return ['favorites', 'want_to_see'].includes(purpose)
  }

  return {
    getListResult,
    isListAvailable,
    getListSubTitle,
    getListTitle,
    getMediumUrl,
  }
}
