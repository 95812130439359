export default defineI18nConfig(() => ({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'en',
  messages: {
    en: {
      auth: {
        signInTitle: 'Sign in to your account',
        email: 'Email address',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        signInCTA: 'Sign in',
        switchToCreateAccount: `Don't have an account?`,
        swtichToCreateAccountCTA: 'Create your account now',
        switchToSignIn: 'Already have an account?',
        switchToSignInCTA: 'Sign in now',
        profileName: 'Name',
        userName: 'Username',
        visibility: 'Visibility',
        image: 'Image',
        errors: {
          emailRequired: 'Email is required',
          emailInvalid: 'Must be a valid email',
          linkWorksOnlyOnSameBrowser:
            'The password recovery links only works on the device or browser where the original reset request was made.',
          passwordRequired: 'Password is required',
          nameTooLong: 'Name is too long',
          usernameRequired: 'Username is required',
          usernameTooShort: 'Username is too short',
          usernameTooLong: 'Username is too long',
          usernameInvalid:
            'Username only allows letters, numbers, and underscores _',
          usernameTaken: 'Username is already taken',
          tosRequired:
            'You must accept the terms of service and privacy policy',
        },
        sendPasswordLinkInfoOnlyThisBrowser:
          'The sent link only works on this device and browser.',
        sendPasswordLinkTitle: 'Send Password Link',
        sendPasswordLinkCTA: 'Send Password Link',
        sendPasswortLinkSuccess: 'Email sent successfully. Check your inbox.',
        resetPasswordTitle: 'Reset your password',
        newPassword: 'New password',
        resetPasswordSaveNewPasswordCTA: 'Save new password',
        resetPasswordNewPasswordSuccess: 'New passwort saved successfully',
        resetPasswordLinkExpired: 'Email link is invalid or has expired',
        resetPasswordUnknownError: 'No password recovery event found.',
        requirements: {
          uppercaseLetter: 'Uppercase letter',
          lowercaseLetter: 'Lowercase letter',
          number: 'Number',
          specialCharacter: "Special character (e.g. !?<>{'@'}#$%)",
          length: '> {0} characters',
        },
        invite: {
          sharePro: {
            title: `Welcome`,
            needToBeAuthenticated: `You've been invited to Quo Pro. To see and accept the invitation, please sign in or create a Quo Basic account, before you come back
            to this page.`,
            invitedBy: `You've been invited to Quo Pro by:`,
            acceptText: `By clicking "Join Quo Pro for free", you join Quo Pro with your existing account. Both of your accounts will remain seperate and you decide which information you want to share between eachother.`,
            acceptCTA: `Join Quo Pro for free`,
            alreadyPro: `You've been invited to Quo Pro. Happily, you are already a Quo Pro.`,
            success: {
              title: 'Welcome to Quo Pro!',
              message: 'You are now part of the Quo Pro community.',
              cta: 'Start your journey',
            },
          },
        },
        goToMapCTA: 'Go to Map',
        registerCTA: 'Register',
        registerTitle: 'Create your account',
        registerEmailSentTitle: 'Check your email to confirm',
        registerEmailSentMessage:
          "Continue the registration by clicking the link in the email we've sent you. If you don't receive an email within a few minutes, please check your spam folder 📧.",
        registerConfirmEmailTitle: 'Confirm your email',
        registerConfirmEmailSuccessTitle: 'Email successfully confirmed',
        registerConfirmEmailErrorMessage:
          'Email confirmation failed. Try to resend the confirmation email.',
        registerConfirmEmailCreateQuoProCTA:
          "Let's create your Quo Pro Profile!",
        createQuoProProfileTitle: 'Create your Quo Pro Profile',
        createQuoBasicProfileTitle: 'Create your Quo Basic Profile',
        createQuoProProfileNameDescription:
          'Your name or nickname or whatever you want to be called.',
        createQuoProProfileUserameDescription: 'Your unique username.',
        createQuoProProfileCTA: 'Check out to start your subscription',
        createQuoBasicProfileCTA: 'Create Profile',
        createProfile: {
          previewTitle: 'Preview',
          previewSubtitle: 'This is how your profile will look like',
          chooseUserNameFirst: 'Choose your username first',
        },
        checkout: {
          quoPro: {
            title: 'Thank you for your purchase!',
            welcome: 'And welcome to the Quo Community.',
            text: 'We are only getting started. You are still early, in fact, you are Quo Pro #',
            cta: 'Start your journey',
          },
          quoBasic: {
            title: 'Welcome to Quo!',
            text: 'We are happy to have you here and hope you enjoy discovering new places.',
            cta: 'Start your journey',
          },
        },
      },
      avatarupload: {
        chooseImage: 'Choose your profile image',
        uploading: 'Uploading...',
        deleteImage: 'Delete image',
        errorMustSelectImage: 'You must select an image to upload.',
      },
      booking: {
        bookDirectly: 'Book directly',
        bookOnBookingCom: 'Booking.com',
        bookOnAirbnb: 'Airbnb',
        bookOnHotelsCom: 'Hotels.com',
      },
      bottomSheetList: {
        title: 'Add to list',
        createNewListCTA: 'Create a new list',
      },
      bottomSheetListCreate: {
        title: 'Create new list',
        createListCTA: 'Create list',
        errors: {
          nameRequired: 'Name is required',
          nameTooLong: 'Name is too long. Max 64 characters.',
        },
      },
      bottomSheetListEdit: {
        title: 'Edit list',
        saveListCTA: 'Save',
        deleteListDialog: {
          title: 'Delete list',
          question: 'Are you sure you want to delete this list?',
          cancelCTA: 'Cancel',
          confirmCTA: 'Delete',
        },
      },
      bottomSheetMapStyle: {
        title: 'Choose map style',
      },
      bottomSheetPlaceCreate: {
        title: 'Add a new place',
        intro: 'There are three easy ways to add a new place:',
        selectOnMap: 'Select on Quo map',
        pasteGps: 'Paste GPS coordinates',
        pasteGoogleMaps: 'Paste Google Maps share link',
        information: 'Only public places should be added.',
      },
      bottomSheetPlaceCreateQuo: {
        title: 'Add a new place',
        instruction: {
          goToPlace: `Go to the place on the map, right click (desktop) or long click
          (mobile), and select "Add a new place".`,
          zoomIn:
            'Make sure that you zoom in enough to place the pin precisely.',
          switchToSatelliteView:
            'You might want to switch to the satellite view for better positioning.',
          activateGps:
            'Activate GPS positioning to find the location where you are right now.',
        },
        addPlace: {
          latitude: 'Latitude',
          longitude: 'Longitude',
          pasteFromClipboard: 'Paste coordinates',
          pasteFromClipboardErrorClipboardEmpty:
            'Your clipboard seems to be empty.',
          pasteFromClipboardErrorNotLatLon:
            'No valid coordinates have been pasted.',
          pasteFromGoogleMaps: 'Paste Google Maps Link',
          pasteFromGoogleMapsErrorClipboardEmpty:
            'Your clipboard seems to be empty.',
          pasteFromGoogleMapsErrorLinkNotRecognized:
            'Your link could not be recognized. It should start like this:',
          pasteFromGoogleMapsErrorNoLinkFound:
            'It seams like there is no link in your pasted text.',
          pasteFromGoogleMapsMultipleCoordinatesFound:
            'Multiple coordinates found. Please choose:',
          pasteGoogleMapsLinkHere: 'Paste Google Maps link here',
          supportedCoordinatesFormats:
            'Supported paste format (lat, lon): 72.0000, 36.000',
          placeTitle: 'Name',
          placeTitlePlaceholder: 'e.g. "Colosseum"',
          category: 'Category',
          newCategory: 'New',
          categoryInstruction:
            'You can choose an existing category or create a new one. Or leave it empty.',
          agreeToTOS: 'By creating this place, you agree to the',
          termsOfService: 'Terms of Service',
          createPlaceCTA: 'Create place',
        },
        placeCreated: {
          intro: 'Here comes your newly created place 🎉',
          startWorking:
            'Quo will try to verify this entry. You can start using it immediatly!',
          addToListCTA: 'Add to list',
          checkinCTA: 'You are here? Checkin!',
          thanks: 'Thank you! There is so much to discover on this planet.',
          createNewPlaceCTA: 'Create another place',
          closeCTA: 'Close',
        },
        errors: {
          categoryTooLong: 'Category is too long',
          latRequired: 'Latitude is required',
          latMustBeNumeric: 'Latitude must be numeric',
          latLonRequired: 'Latitude and longitude are required',
          lonRequired: 'Longitude is required',
          lonMustBeNumeric: 'Longitude must be numeric',
          nameRequired: 'Name is required',
          nameTooLong: 'Name is too long',
        },
      },
      bottomSheetMention: {
        title: 'Add a note for this place',
        textareaPlaceholder:
          'Add your thoughts, recommendations, or anything else you want to remember about this place...',
        publicComingSoon: 'Public coming soon',
        postCTA: 'Post',
      },
      detailSegment: {
        social: {
          title: ({ named }) => `Find ${named('name')} on:`,
        },
      },
      detailSheet: {
        checkins: 'Checkins yet | Checkin | Checkins',
        likes: 'Likes yet | Like | Likes',
      },
      dateTime: {
        today: 'Today',
        tomorrow: 'Tomorrow',
      },
      eatingDrinking: {
        cuisine: 'Cuisine',
        dishes: 'Dishes',
        drinks: 'Drinks',
        amenities: 'Amenities',
        menu: 'Menu',
        reserveTableDirectly: 'Reserve table (directly)',
        reserveOpentable: 'OpenTable',
      },
      feed: {
        title: 'Latest and greatest places',
      },
      general: {
        quoPro: 'Quo Pro',
        signInCTA: 'Sign In',
        registerText: 'Don’t have an account?',
        registerCTA: 'Create your account now',
        visibility: {
          private: 'Private',
        },
        allRightsReserved: 'All rights reserved',
        support: 'Support',
      },
      homepage: {
        quoMap: 'Quo Map',
        toTheMap: 'Quo Map',
        hero: {
          title: 'Keep all your places in one place',
          titleVar1: 'Bring all your places together',
          lead: 'Quo is a map-based travel guide and store of locations, where you can add, collect and share all of your favorite places — near and far.',
          cta: 'Subcribe now',
          learnMore: 'Learn about Quo Map',
        },
        primaryFeatures: {
          title: 'Created to create',
          lead: `With Quo it's super easy to store all your places in just one place: your Quo Map. Conveniently organize them in lists and add notes. All nice and clean.`,
        },
        secondaryFeatures: {
          subtitle: 'True content',
          title: 'A whole world to discover',
          lead: 'Imagine reaching out to new places and having the best and most trustworthy information at hand. Here we go.',
          intro:
            'The Quo travel guide is based on three pillars to make sure you land on truly marvelling spots:',
          pillarOneTitle: 'You.',
          pillarOneText: `It's your journey, and therefore, it starts with you. Easily add and collect your wanna-go-to places.`,
          pillarTwoTitle: 'Your friends & peers.',
          pillarTwoText: `That one friend had his best Spaghetti Amatriciana in Rome at this very location? Surely worth a visit!`,
          pillarThreeTitle: 'Researched content.',
          pillarThreeText: `Quo produces it's own thoroughly researched content. Background information, history, lists, do's & don'ts, tipping, ... you get it.`,
          noTitle: 'No spam. No ads.',
          noText:
            'Quo is built around your true interests. No ads, no spam, no cry-baby-comments. Reading notes is fun again!',
          outro: `You are still early to our party. Much has yet to be created. Nevertheles, there is already more content than a trip of the lifetime will require.`,
        },
        pricing: {
          subtitle: 'Choose your Quo',
          title: 'Great map, small price',
          lead: 'Quo covers the entire planet. It relies entirely on subscriptions. Through them, Quo can effectively exclude all bots and spammers from the service.',
        },
        faqs: {
          title: 'Frequently asked questions',
        },
        embark: {
          title: 'Ready to embark your journey?',
          lead: 'Join the Quo community of free spirits and curious minds, of doers and dreamers, of explorers and adventurers, of sun seekers and mountain climbers, of sightseers and wind chasers, of rain feelers, and of you and me.',
          cta: 'Let’s jump in',
        },
      },
      lists: {
        defaultTitles: {
          purposes: {
            checkins: 'Seen',
            favorites: 'Favorites',
            guides: 'Guides',
            want_to_see: 'I want to see',
            likes: 'Likes',
            list: 'List',
            mentions: 'Mentions',
          },
        },
        entries: 'Entries | Entry | Entries',
        lists: 'Lists',
        sortings: {
          insertedAtAsc: 'Oldest first',
          insertedAtDesc: 'Newest first',
          nameAsc: 'A-Z',
        },
      },
      openingHours: {
        open: 'Open',
        closed: 'Closed',
        openClosesAtTime: ({ named }) => `Open · closes at ${named('time')}`,
        closesAtTime: ({ named }) => `Closes at ${named('time')}`,
        closedOpensAtTime: ({ named }) => `Closed · opens at ${named('time')}`,
        opensAtTime: ({ named }) => `Opens at ${named('time')}`,
        showMore: 'Show more hours',
        clear: 'Clear',
      },
      pages: {
        map: 'Map',
        support: 'Support',
        legal: 'Legal',
      },
      permanentlyClosed: {
        today: 'Closes today permanently',
        yesterday: 'Closed yesterday permanently',
        past: ({ named }) =>
          `Permanently closed since ${named('formattedDateClosed')}`,
        tomorrow: 'Closes tomorrow permanently',
        future: ({ named }) =>
          `Will close permanently on ${named('formattedDateClosed')}`,
      },
      profile: {
        inspiring: 'Inspiring',
        inspiredBy: 'Inspired by',
        sendInvite: 'Send Invite',
        buyQuoPro: {
          lead: 'Start your journey with',
          intro: `All your places in one place. Stay organized, plan, like, comment, and share. It's all there for you.`,
          cta: 'Buy Plan',
          bullets: {
            one: 'Add new places, make lists, like, comment, follow, and be followed!',
            two: 'Priority access to new features',
            three: 'Earn that badge',
          },
        },
        menu: {
          editProfile: 'Edit Profile',
          signOut: 'Sign Out',
        },
      },
      profileUser: {
        since: 'Since',
      },
      product: {
        choose: {
          overTitle: 'Choose your Quo',
          title: 'Great service, small price',
          lead: "Quo covers the entire planet, and all your needs. It's the travel guide made for you!",
          month: 'month',
          quoBasic: {
            badge: '',
            description:
              'Surfing the map is all that you want? Then this is the plan for you.',
            features: {
              one: 'Add new places, make lists, like, comment, follow, and be followed!',
              two: 'Priority access to new features',
              three: 'Earn that badge',
            },
            cta: 'Get started today',
            fineprint: 'Upgrade to Quo Pro anytime.',
          },
          quoPro: {
            badge: 'recommended',
            description:
              'The perfect plan if you like traveling and keep all you places in one place.',
            features: {
              one: 'Add new places, make lists, like, comment, follow, and be followed!',
              two: 'Priority access to new features',
              three: 'Earn that badge',
              four: 'four',
              five: 'five',
            },
            cta: 'Get started today',
            fineprint: 'Billed yearly.',
          },
        },
      },
      sharePro: {
        intro: 'Share { quosub } with your partner for free.',
        quoProSubscription: 'Quo Pro',
        startNowCTA: `Start now!`,
        waitingApproval: 'Waiting for approval',
        sendThisLink: 'Send this link to your partner',
        expiryNote: 'Link valid through { expirydate }.',
        notShareNote: 'Do not share in public!',
        stepTwo:
          'By clicking the shared link, your partner can upgrade its existing Quo Basic account to Quo Pro.',
        closeCTA: 'Close',
      },
    },
    de: {
      welcomeTo: 'Willkommen bei',
      goTo: 'Gehe zu',
      general: {
        quoPro: 'Quo Pro',
        signIn: 'Einloggen',
      },
      pages: {
        map: 'Karte',
        support: 'Unterstützung',
        legal: 'Rechtliches',
      },
      profile: {
        inspiring: 'Inspiriert',
        inspiredBy: 'Inspiriert von',
        sendInvite: 'Einladung senden',
        buyQuoPro: {
          lead: 'Beginne deine Reise mit',
          intro: `Alle deine Plätze an einem Platz. Organisiere, plane, like, kommentiere und teile. Alles da - für Dich!`,
          cta: 'Kaufe Abo',
          bullets: {
            one: 'Neue Orte hinzufügen, Listen erstellen, folgen und gefolgt werden!',
            two: 'Zugang zu neuen Funktionen',
            three: 'Bekomme dieses Abzeichen',
          },
        },
      },
    },
    fr: {
      welcomeTo: 'Bienvenue',
      goTo: 'Aller à',
      general: {
        quoPro: 'Quo Pro',
        signIn: 'Se connecter',
      },
      pages: {
        map: 'Carte',
        support: 'Soutien',
        legal: 'Légal',
      },
      profile: {
        inspiring: 'Inspirer',
        inspiredBy: 'Inspiré par',
        sendInvite: 'Envoyer une invitation',
        buyQuoPro: {
          lead: 'Commence ton voyage avec',
          intro: `Tous vos endroits en un seul endroit. Organisez, planifiez, aimez, commentez et partagez. Tout est là pour vous!`,
          cta: 'Acheter un abonnement',
          bullets: {
            one: 'Ajouter de nouveaux lieux, créer des listes, suivre et être suivi !',
            two: 'Accès à de nouvelles fonctionnalités',
            three: 'Obtiens cette médaille',
          },
        },
      },
    },
  },
}))
